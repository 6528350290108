import { styled } from 'twin.macro';

export const RowStyled = styled.div`
  background: ${({ theme }) => theme.homePage.backgroundRibbon};
  font-size: 10px;

  &.v-searchPage {
    width: 100%;
    margin-top: 10px;
    &--loading {
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .v-ytbPage__search {
    .v-searchPage {
      &--col {
        flex-basis: 50%;
      }
    }
  }
  .v-searchPage {
    &--searchNoneResult {
      color: ${({ theme }) => theme.searchPage.colorTitle};
      font-size: 2.4em;
      text-align: center;
      text-transform: uppercase;
      grid-column: 1/-1;
    }
    &--title {
      font-size: 2.4em;
      text-align: left;
      margin: 1.375em 0;
      color: ${({ theme }) => theme.searchPage.colorTitle};
      span {
        color: ${({ theme }) => theme.searchPage.colorKey};
      }
    }
    &--suggest {
      font-size: 1.8em;
      color: ${({ theme }) => theme.searchPage.suggest};
    }
    &--col {
      padding: 5px 0px;
      /* margin-bottom: calc(2.4rem * var(--multiplierFontSize)); */
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      /* flex-basis: 50%; */
    }
    &--row {
      margin: 20px 0 45px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
    &--container {
      width: 100%;
      padding: 0 15px;
      display: block;
      height: auto;
      font-size: 1em;
    }
    &--tag {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-searchPage {
      &--row {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }
    }
  }

  @media (min-width: 992px) {
    .v-searchPage {
      &--row {
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
      }
    }
  }
  @media (min-width: 1024px) {
    .v-searchPage {
      &--row {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  @media (min-width: 1440px) {
    .v-searchPage {
      &--row {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
  @media (min-width: 1680px) {
    .v-searchPage {
      &--col {
        flex-basis: 16.66%;
      }
    }
  }
`;
