import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import pushAnalytics from '@/services/analytics/Analytics';
import { Button } from 'react-bootstrap';

class Tag extends React.Component {
  componentDidMount() {}

  componentWillReceiveProps() {}

  render() {
    const { genre, t } = this.props;
    if (!genre || (genre && !genre.length)) return null;
    return (
      <div className="v-searchPage--tag">
        <div className="v-searchPage--title">{t('search.tags')}:&nbsp;</div>
        <div style={{ margin: '20px 0', display: 'flex', flexWrap: 'wrap' }}>
          {genre.map((item, index) => {
            return (
              <Button
                key={index}
                style={{
                  margin: '0.2rem',
                  background: '#2574d4',
                  color: '#fff',
                }}
                onClick={() =>
                  pushAnalytics('click', {
                    content_type: 'button',
                    item_name: item,
                  })
                }
              >
                {item}
              </Button>
            );
          })}
        </div>
      </div>
    );
  }
}

Tag.propTypes = {
  genre: PropTypes.array,
};
export default withRouter(withTranslation()(Tag));
