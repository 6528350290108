import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RibbonItem from '@/shared/components/ribbon/components/ribbonItem';
import { contentTypes } from '@/shared/utils/utils';
import pushAnalytics from '@/services/analytics/Analytics';

class SearchResult extends React.Component {
  componentDidMount() {}

  componentWillReceiveProps() {}

  _handleClick(item, index) {
    let contentType = contentTypes(item);
    pushAnalytics('select_content', {
      content_type: contentType,
      item_id: item.slug,
      widget: 'Search Result',
      order: index + 1,
    });
  }

  render() {
    const { isLoadingSearch, entities, resMessage } = this.props;
    if (isLoadingSearch) return null;
    if (entities && entities.items.length > 0) {
      return entities.items.map((item, index) => {
        return (
          <div
            className="v-searchPage--col"
            key={index}
            onClick={() => this._handleClick(item, index)}
          >
            <RibbonItem item={item} index={index} key={index} />
          </div>
        );
      });
    }

    return (
      <div
        className="searchNoneResult v-searchPage--searchNoneResult"
        style={{ marginTop: '15%', width: '100%' }}
      >
        <p
          style={{
            color: '#555555',
            fontSize: 32,
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {resMessage}
        </p>
      </div>
    );
  }
}
SearchResult.propTypes = {
  isLoadingSearch: PropTypes.bool,
  entities: PropTypes.object,
  resMessage: PropTypes.string,
};
export default withRouter(withTranslation()(SearchResult));
