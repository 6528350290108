import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RibbonItem from '@/shared/components/ribbon/components/ribbonItem';
import { Col } from 'react-bootstrap';

class Suggest extends React.Component {
  componentDidMount() {}

  componentWillReceiveProps() {}

  render() {
    const { related, t } = this.props;
    if (related && !related.length) return null;
    return (
      <div>
        <div className="v-searchPage--title">{t('search.hint')}</div>
        <div className="v-searchPage--row my-5]">
          {related.map((item, index) => {
            return (
              <Col className="v-searchPage--col" key={index}>
                <RibbonItem item={item} index={index} />
              </Col>
            );
          })}
        </div>
      </div>
    );
  }
}
Suggest.propTypes = {
  related: PropTypes.array,
};
export default withRouter(withTranslation()(Suggest));
